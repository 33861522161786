<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th>Voucher Date</th>
                        <th>Voucher No</th>
                        <th width="4%">Bill Date</th>
                        <th>Bill Number</th>
                        <th>Party Name</th>
                        <th>Item</th>
                        <th>Accounting Head</th>
                        <th>Base Amount</th>
                        <th>VAT Amount</th>
                        <th>Total Bill Amount</th>
                        <th>HS / Service Code</th>
                        <th>VDS Section Head</th>
                        <th>VDS  Rate</th>
                        <th>VDS Amount</th>
                        <th>Section Ref</th>
                        <th>TDS Section Head</th>
                        <th>Tax Rate</th>
                        <th>TDS Amount</th>
                        <th>Payment Status</th>
                        <th>Note</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in tableItems" :key="item.id">
                        <td>{{ item.voucher_date }}</td>
                        <td>{{ item.voucher_no }}</td>
                        <td>{{ item.bill_date }}</td>
                        <td>{{ item.bill_number }}</td>
                        <td>{{ item.party_name }}</td>
                        <td>{{ item.product_name }}</td>
                        <td>{{ item.account_head_name }}</td>
                        <td class="text-right">{{ commaFormat(item.base_amount) }}</td>
                        <td class="text-right">{{ commaFormat(item.vat_amount) }}</td>
                        <td class="text-right">{{ commaFormat(item.bill_amount) }}</td>
                        <td>{{ item.vds_code }}</td>
                        <td>{{ item.vds_section_ref }}</td>
                        <td>{{ item.vds_rate }}</td>
                        <td class="text-right">{{ commaFormat(item.vds_amount) }}</td>
                        <td>{{ item.tds_code }}</td>
                        <td>{{ item.tds_section_ref }}</td>
                        <td>{{ item.tds_rate }}</td>
                        <td class="text-right">{{ commaFormat(item.tds_amount) }}</td>
                        <td>{{ item.payment_status }}</td>
                        <td>{{ item.tds_vds_note }}</td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import figureFormatter from '@/services/utils/figureFormatter'

const route = useRoute();
const router = useRouter();
const { commaFormat } = figureFormatter();

const props = defineProps({
    tableItems: {
        type: Array,
        default: []
    }
})
</script>

<style scoped>
.custom-table-padding {
    width: 210%
}
</style>