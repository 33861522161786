import Network from '@/services/network'

export default function handleCompliance() {
    const network = new Network;
    
    // ********************* Tds-Vds-Register-start *****************
    const fetchTdsVdsRegister = (query) => {
        return network.api('get', '/compliance/tds-vds/register-list' + query);
    }
    // ********************* Tds-Vds-Register-End *****************


    return {
        fetchTdsVdsRegister
    }
}